import * as React from "react"
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import {Container, Typography} from "@mui/material";
import {Link} from "gatsby-theme-material-ui";


// markup
const BlogPage = () => {
    return (
        <Layout title={"Blog"}>
            <main>
                <Hero title={"Jurybox Blog"}
                      subtitle={"Posts about relevant stuff"}/>

                <Container style={{paddingTop: "3em"}}>

                    <div>
                        <Link to={"/blog/jury-selection-software"}>Why use jury selection software?</Link>
                        <Typography variant={"caption"}> - Feb 24, 2021</Typography>
                    </div>
                    <br />
                    <div>
                    <Link to={"/blog/jury-selection-apps"}>Top 3 jury selection apps on the market today</Link>
                        <Typography variant={"caption"}> - Sept 21, 2020</Typography>
                    </div>
                    <br />
                    <div>
                    <Link to={"/blog/jury-selection"}>How is a jury selected?</Link>
                        <Typography variant={"caption"}> - Sept 2, 2020</Typography>
                    </div>


                    <br />
                    <br />

                    <Typography color={"primary"}>Disclaimer</Typography>
                    <Typography variant={"caption"}>The articles on this site are for information only and in some cases
                        only represents the opinion of the author. The information does not constitute legal advice and
                        should not be used as a substitute for obtaining legal advice from an attorney.</Typography>


                </Container>
            </main>
        </Layout>
    );
};

export default BlogPage;
